<template>
	<!-- 个人中心 基本信息卡片 -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }" :headStyle="{ paddingRight: 0 }">
		<template #title>
			<h6 class="font-semibold m-0">基本信息</h6>
		</template>
		<!-- <a-button type="link" slot="extra">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827" />
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827" />
			</svg>
		</a-button> -->

		<a-descriptions title="" :column="1" v-if="userInfo.user_type==='stu'">
			<a-descriptions-item label="账号">{{ userInfo.username }}</a-descriptions-item>
			<a-descriptions-item label="">
					<a-button type="secondary" block @click="showIconModal">修改头像</a-button>
      </a-descriptions-item>
			<a-descriptions-item label="">
					<a-button type="secondary" block @click="showPwdModal">修改密码</a-button>
      </a-descriptions-item>
      
		</a-descriptions>
		<a-descriptions title="" :column="1" v-else>
			<a-descriptions-item label="昵称">{{ userInfo.nickname }}</a-descriptions-item>
			<a-descriptions-item label="手机号">{{ userInfo.mobile ? userInfo.mobile : "无" }}</a-descriptions-item>
			<a-descriptions-item label="UID">{{ userInfo.user_id }}</a-descriptions-item>
			<a-descriptions-item label="创作身份">{{ userInfo.is_vip === 1 ? userInfo.vip_expiretime_text : "普通用户" }}</a-descriptions-item>
			<a-descriptions-item label="分销商">{{ userInfo.reseller ? userInfo.reseller.reseller_json.name : "无" }}</a-descriptions-item>
			<a-descriptions-item label="加入时间">{{ joinTime }}</a-descriptions-item>
		</a-descriptions>
    <a-modal title="修改密码" :visible="pwdModal" cancelText="取消" okText="确定" :confirm-loading="createLoading" @ok="createOkHandle" @cancel="hidePwdModal">
      <a-descriptions title="" :column="1" bordered>
        <a-descriptions-item label="原密码">
          <a-input type="password" v-model="oldpwd" placeholder="请输入原密码"/>
        </a-descriptions-item>
        <a-descriptions-item label="新密码">
          <a-input type="password" v-model="newpwd" placeholder="请输入6~20位数字+字母或符号组合"/>
        </a-descriptions-item>
        <a-descriptions-item label="确认新密码">
          <a-input type="password" v-model="rnewpwd" placeholder="请再次输入新密码"/>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <a-modal title="修改头像" :visible="iconModal" cancelText="取消" okText="确定" :confirm-loading="createLoading" @ok="createOkHandleIcon" @cancel="hideIconModal">
      <a-descriptions title="" :column="1" bordered>
        <a-descriptions-item label="当前头像">
						<a-avatar :size="74" shape="square" :src="userInfo.avatar" />
        </a-descriptions-item>
        <a-descriptions-item label="选择头像">
            <div class="image" v-if="picture.image">
                <img :src="picture.showImage" alt="" />
                <div class="delete">
                    <a-icon type="delete" :style="{ fontSize: '20px' }" @click="deleteUploadPicture" />
                </div>
            </div>
            <a-upload name="file" :show-upload-list="false" :action="upload.action" :headers="upload.headers" :accept="upload.accept" :data="upload.data" :before-upload="uploadPictureBefore" @change="uploadPictureChange">
                <!--<a-icon :type="upload.loading ? 'loading' : 'plus'" :style="{ fontSize: '24px', color: '#8895a4' }" />-->
                <a-button> <a-icon :type="upload.loading ? 'loading' : 'upload'" :style="{ fontSize: '24px', color: '#8895a4' }" /> 选择一张图片 </a-button>
            </a-upload>
            <div class="text" v-if="!upload.loading">
                支持JPG、PNG格式500K内
            </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
	</a-card>
</template>

<script>
  import { apiList } from "@/api/api"
	import { timestampToTime } from "@/utils/tools.js"
	import { checkPassword, checkAgainPassword } from "@/utils/verify"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {
				joinTime: "",
        oldpwd: "",
        newpwd: "",
        rnewpwd: "",
        pwdModal: false,
				createLoading: false, // 异步加载状态
        iconModal: false,
        // 添加图片
        picture: {
            show: false,
            type: 1,
            active: null,
            // image: "https://osaigcimg.su.bcebos.com/uploads/20231120/f4217861dc515586c9a986d14023f54e_2.jpg",
            image:'',
            showImage: '',
        },
        // 上传信息
        upload: {
            loading: false,
            action: apiList.user.upload.url,
            headers: {
                token: ""
            },
            accept: "image/png, image/jpeg",
            data: {
                is_user_image: 1
            }
        },
        canUpload: true,
			}
		},
		computed: {
			...mapGetters("user", ["userInfo", "token"])
		},
		created() {
			this.joinTime = timestampToTime(this.userInfo.createtime)
      this.upload.headers.token = this.token
		},
    methods: {
			...mapActions("user", ["getUserInfo"]),
      showPwdModal() {
        this.pwdModal = true
      },
      hidePwdModal() {
        this.pwdModal = false
        this.oldpwd = ""
        this.newpwd = ""
        this.rnewpwd = ""
      },
			createOkHandle() {
				if (!this.oldpwd) {
					this.$message.warning("请输入原密码")
					return
				}
				if (!this.newpwd) {
					this.$message.warning("请输入新密码")
					return
				}
        const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])[\w!@#$%^&*()-+=<>?/\[\]{}|~]{6,20}$/
        if (!reg.test(this.newpwd)) {
					this.$message.warning("密码格式有误, 请输入6~20位数字+字母或符号组合")
					return
        }
				if (!this.rnewpwd) {
					this.$message.warning("请再次输入新密码")
					return
				}
        if (this.rnewpwd !== this.newpwd) {
					this.$message.warning("两次新密码输入的不一致")
					return
        }
				const obj = { oldpwd: this.oldpwd, newpwd: this.newpwd, rnewpwd: this.rnewpwd }
				this.createLoading = true
				this.$http("user.resetpwd", obj).then(res => {
					if (res.code === 1) {
            this.$message.success(res.msg)
				    this.hidePwdModal()
						return new Promise((resolve, reject) => {
							this.$store.dispatch("user/logout")
							this.$emit("toggleSettingsDrawer", false)
							resolve()
						}).catch(() => {})
					} else {
						// this.$message.error(res.msg)
					}
					this.createLoading = false
				})
			},
      showIconModal() {
        this.iconModal = true
      },
      hideIconModal() {
        this.iconModal = false
      },
			createOkHandleIcon() {
				if (this.picture.image === '') {
					this.$message.warning("请上传图片")
					return
				}
				const obj = { avatar: this.picture.showImage }
				this.createLoading = true
				this.$http("user.profile", obj).then(res => {
					if (res.code === 1) {
            this.$message.success(res.msg)
				    this.hideIconModal()
						this.getUserInfo(this.token)
					} else {
						// this.$message.error(res.msg)
					}
					this.createLoading = false
				})
			},
      // 上传图片之后
      uploadPictureChange(info) {
          if (!this.canUpload) {
              return
          }
          if (info.file.status === "uploading") {
              this.upload.loading = true
              return
          }
          if (info.file.response.code != 1) {
              this.upload.loading = false
              this.$message.error(info.file.response.msg)
              return
          }
          if (info.file.status === "done") {
              this.upload.loading = false
              const time = new Date().getTime()
              this.picture.image = info.file.response.data.url
              this.picture.showImage = info.file.response.data.fullurl
          }
      },
      // 上传图片之前
      uploadPictureBefore(file) {
          this.canUpload = true
          const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
          if (!isJpgOrPng) {
              this.$message.error("仅支持JPG、PNG格式!")
              this.canUpload = false
          }
          const isLt2M = file.size / 1024 / 1024 < 0.5
          if (!isLt2M) {
              this.$message.error("图片大小超过500KB!")
              this.canUpload = false
          }
          return isJpgOrPng && isLt2M
      },
      // 删除上传图片
      deleteUploadPicture() {
          this.$confirm({
              title: "确定要删除图片吗？",
              content: "",
              okText: "确定",
              okType: "danger",
              cancelText: "取消",
              onOk: () => {
                  this.picture.image = ""
                  this.picture.showImage = ""
                  this.picture.active = null
              },
              onCancel: () => {}
          })
      },
    },
	}
</script>
